import { lazy, Suspense } from 'react'
// import Preloader from '../../@components/layout/Preloader';

const Main = lazy(() => import('./main'));

const pageConfig = {
    path: "/registrasi-akun",
    element: <Suspense fallback={<div>Loading ....</div>}><Main/></Suspense>,
}

export default pageConfig