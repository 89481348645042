import { combineReducers } from '@reduxjs/toolkit';
// import { loadingBarReducer } from 'react-redux-loading-bar'

// import apps from '../app/modules/backend/#globalstore/appReducers';
// import auth from '../app/modules/backend/#globalstore/authReducers';
// import users from '../app/modules/backend/#globalstore/userReducers';

const createReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
    //   apps,
    //   auth,
    //   users,
    //   loadingBar: loadingBarReducer,
    });
  
    /*
      Reset the redux store when user logged out
       */
    // if (action.type === 'user/userLoggedOut') {
    //   // state = undefined;
    // }
  
    return combinedReducer(state, action);
  };
  
  export default createReducer;