import {
    createBrowserRouter,
} from "react-router-dom";

import signinClientConfig from '../modules/client/signin/pageConfig';
import signupClientConfig from '../modules/client/signup/pageConfig';

//Error Page
import ErrorPage404 from '../modules/error/404';

const routes = createBrowserRouter([
    {
        path: "/",
        children: [
            signinClientConfig,
            signupClientConfig
        ],
    },
    {
        path: "*",
        element: <ErrorPage404 />
    }
]);

export default routes;