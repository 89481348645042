import { Provider } from 'react-redux';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { RouterProvider } from 'react-router-dom'
import router from './configs/routerConfig';
import store from './store';
import AppContext from './AppContext';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';


const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: 'roboto',
    },
  },
});

const withAppProviders = (Component) => (props) => {
  const WrapperComponent = () => (
    <ThemeProvider theme={theme}>
      <AppContext.Provider value>
          <Provider store={store}>
            <div className="Application">
              <RouterProvider router={router} />
              <Component {...props} />
            </div>
          </Provider>
      </AppContext.Provider>
    </ThemeProvider>
  );

  return WrapperComponent;
};

export default withAppProviders;
